
import config from '@/config'
const serviceUrl = config.serviceUrl
export const LoginMixin = {
    data () {
        return {
            service: serviceUrl,
        }
    },
    mounted () {
        let service = this.$route.query.service;
        if (service) {
            this.service = service;
        }
    },
    methods: {
        loginSuccessOption (res) {
            this.$message.success("登录成功!");
            var accessToken = res.data;
            if(this.service.indexOf('?') > -1) {
                window.location.href =
                  this.service + "&" + "accessToken=" + accessToken;
            } else {
                window.location.href =
                  this.service + "?" + "accessToken=" + accessToken;
            }
        }
    },
}
