<template>
  <AccountLayout>
    <a-card hoverable class="account-right-container">
      <div class="login-container">
        <div class="login-container-header">
          <a-button shape="circle" icon="arrow-left" @click="goLogin" />
          选择公司主体
        </div>
        <company-form
          @submit="confirmLogin"
          :companyList="companyList"
          :formLoading="formLoading"
        ></company-form>
      </div>
    </a-card>
  </AccountLayout>
</template>



<script>
import CompanyForm from "./components/company-form";
import { loginAPI } from "@/api/login";
import { deepCopy } from "@/libs/utils";
import { LoginMixin } from "@/views/mixins/login";
export default {
  name: "multi-company",
  props: {
    msg: String,
  },
  mixins: [LoginMixin],
  data() {
    return {
      formLoading: false,
      companyList: [],
      formFields: {},
      loginRef: "",
    };
  },
  components: {
    CompanyForm,
  },
  mounted() {
    this.genParamByRoute(this.$route);
  },
  watch: {
    $route(newRoute) {
      this.genParamByRoute(newRoute);
    },
  },
  computed: {
    loginAPIWrapper() {
      let target;
      switch (this.loginRef) {
        case "email/withCatpcha":
          target = loginAPI.loginEmailWithCaptcha;
          break;
        case "phone/withCatpcha":
          target = loginAPI.loginPhoneWithCaptcha;
          break;
        case "withPwd":
          target = loginAPI.loginWithPwd;
          break;
      }
      return target;
    },
  },
  methods: {
    genParamByRoute(route) {
      if (route.params) {
        this.companyList = route.params.companyList;
        this.formFields = route.params.loginRequest;
        this.loginRef = route.params.loginRef;
      } else {
        this.goLogin();
      }
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
    verifyErrorHandler(res) {
      this.$message.error(res.repMsg + ",错误码:[" + res.repCode + "]");
    },
    analyzerLoginRes(res) {
      if (res.status) {
        this.loginSuccessOption(res);
      } else {
        this.$message.error(res.msg + ",错误码:[" + res.code + "]");
      }
    },
    confirmLogin(data) {
      this.formLoading = true;
      let request = deepCopy(this.formFields);
      request.tenantId = data.companyId;
      this.loginAPIWrapper(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
  },
};
</script>
