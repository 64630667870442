<template>
  <a-form-model
    :hideRequiredMark="true"
    :model="model"
    :rules="rules"
    :wrapper-col="{ span: 24 }"
    ref="form"
    class="login-container-form"
  >
    <a-form-model-item prop="companyId">
      <a-select size="large" v-model="model.companyId">
        <a-select-option v-for="(company, index) in companyList" :value="company.companyId" :key="index">
          {{ company.fullName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        size="large"
        block
        :loading="formLoading"
        @click="doSubmit"
      >
        提交
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { FormMixin } from "@/views/mixins/form";
export default {
  name: "retrieve-pass-captcha-form-model",
  props: {
    formLoading: {
      type: Boolean,
      default: false,
    },
    companyList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      model: {
        companyId: "",
      },
      rules: {
        companyId: [
          {
            required: true,
            message: "公司主体必须选择",
            trigger: "blur",
          },
        ],
      },
    };
  },
};
</script>